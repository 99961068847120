import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
private data =[];

  constructor() { }

  setData(id, data){
    this.data[id]= data;
    console.log(this.data[1])
  }

  getData(id){
    console.log(id)
    return this.data[id];
  }
}
