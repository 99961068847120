import { Injectable } from '@angular/core';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  pedido: { id: number; cliente: number; productos: any[]; total: number;costo_delivery:number;cupon:string; total_price:number,total_price_descuento:number;anotaciones: string; fecha: string; };

  constructor() { }

  init_order() {
    return new Promise((resolve)=>{
      let order = {id:0,cliente:0,productos:[],total:0, total_price:0,costo_delivery:0,cupon:'', anotaciones:'',fecha:'', total_price_descuento:0};
      this.pedido = order;
      console.log(order);
      localStorage.setItem('pedido',JSON.stringify(this.pedido));
      resolve(true);
    });
  }

  get_order() {
    return new Promise( (resolve)=>{
      this.pedido = JSON.parse(localStorage.getItem('pedido'));
      resolve(this.pedido);
    });
  }


 async agregar_carro(item,cantidad:number) {
    console.log(item, cantidad)
    this.pedido = JSON.parse(localStorage.getItem('pedido'));
    console.log(this.pedido);
    if(this.pedido == null){
      this.init_order()
    }
     let existe = this.pedido.productos.find(x => x.id == item.id && !item.detalle);
     console.log(existe)
    if(existe) {
      this.pedido.productos.forEach((data)=>{   
        console.log(data)  
        console.log(item)
           if(data.id == item.id) {
                // data.cantidad = cantidad
                data.quantity =  data.quantity + item.quantity;
                console.log('agrego existe')    
            
            }  
      });
    } else {   
       this.pedido.productos.push(item);
       console.log('agrego 2')
     }
    console.log(this.pedido)
  await  localStorage.setItem('pedido',JSON.stringify(this.pedido));
  }


  quitar_carro(producto,index) {
    return new Promise ((resolve)=>{
      console.log(producto, index)
      this.pedido = JSON.parse(localStorage.getItem('pedido'));
  
      console.log(index)
      this.pedido.productos.splice(index, 1) 
     
       
      localStorage.setItem('pedido',JSON.stringify(this.pedido));
      resolve(this.pedido)
    })
  }

  delete_order() {
    localStorage.removeItem('pedido');
  }


  
  total_cart() {
    return new Promise( (resolve, rejects)=>{
      this.pedido = JSON.parse(localStorage.getItem('pedido'));
      console.log(this.pedido)
      let envio = localStorage.getItem('envio');
      // if(this.pedido.productos.length <= 0) {
      //   resolve(this.pedido.total);
      // } else {
        let total = 0;
        let total_price = 0;
        let total_price_descuento = 0;
        if(this.pedido){
          if(total_price == total_price_descuento){
            console.log("pasada")
          this.pedido.productos.forEach( (item) => {
            total = +total + +item.quantity;
            total_price = +total_price + (+item.price*item.quantity);
            total_price_descuento = +total_price_descuento + (+item.price*1*item.quantity);
          });
         }
          if(envio == 'delivery'){
            let costo_delivery = localStorage.getItem('costo_delivery')
            if(costo_delivery){
              this.pedido.costo_delivery = parseFloat(costo_delivery)
              this.pedido.total = total;
              this.pedido.total_price = total_price + parseFloat(costo_delivery);
              this.pedido.total_price_descuento = total_price_descuento +parseFloat(costo_delivery)
              let totales={total:total, total_price:this.pedido.total_price, total_price_descuento:this.pedido.total_price_descuento}
              console.log(this.pedido)
              localStorage.setItem('pedido',JSON.stringify(this.pedido));
              resolve(totales);
            }else{
              this.pedido.costo_delivery = 0
              this.pedido.total = total;
              this.pedido.total_price = total_price + 0;
              this.pedido.total_price_descuento = total_price_descuento +0
              let totales={total:total, total_price:this.pedido.total_price, total_price_descuento:this.pedido.total_price_descuento}
              console.log(this.pedido)
              localStorage.setItem('pedido',JSON.stringify(this.pedido));
              resolve(totales);
            }
          }else{
            console.log(total, total_price, total_price_descuento)
            this.pedido.total = total;
            this.pedido.total_price = total_price;
            this.pedido.total_price_descuento = total_price_descuento 
            let totales={total:total, total_price:total_price, total_price_descuento:total_price_descuento}
            console.log(this.pedido)
            localStorage.setItem('pedido',JSON.stringify(this.pedido));
            resolve(totales);
          }
        }else{
          let total = 0
          let totales={total:0, total_price:0, total_price_descuento:0}
          resolve(totales)
          console.log('estoy en el reject')
        }
         
    });
  }
  

  total_cart_cupon(cupon){
    return new Promise((resolve)=>{
        let pedido = JSON.parse(localStorage.getItem('pedido'));
      // this.total_cart().then((data)=>{
        // console.log(this.pedido)
        let total = 0;
        let total_price = 0;
        let total_price_descuento_cupon = 0;
        let total_price_cupon = 0
        let total_price_descuento = 0
        let total_price_sin_cupon = 0
        let descuento_cupon = 0
        this.total_cart().then((data:any)=>{
          console.log(data)
          total = data.total
          total_price = data.total_price
          total_price_descuento = data.total_price_descuento
          if(cupon.tipo == 'Monto'){           
            pedido.cupon = cupon.id
            descuento_cupon = cupon.cupon
            total_price_sin_cupon = total_price
            total_price_cupon = total_price - descuento_cupon
            total_price_descuento_cupon = total_price_descuento - descuento_cupon
            if(total_price_descuento < 0 ||total_price_descuento_cupon < 0){
              total_price_descuento = 0.01
              total_price_descuento_cupon = 0.01
              total_price_cupon = 0.01
            }
            pedido.total_price_original= total_price_sin_cupon
            pedido.total_price= total_price_cupon
            pedido.total_price_descuento =total_price_descuento_cupon
            console.log(pedido)
            let totales={total:total,total_price_original:total_price_sin_cupon, total_price:total_price_cupon, total_price_descuento:total_price_descuento_cupon}
            localStorage.setItem('pedido',JSON.stringify(pedido));
            console.log(pedido)
            resolve(totales);
          }else if(cupon.tipo == 'Porcentaje'){
            pedido.cupon = cupon.id
            descuento_cupon = 1 - cupon.cupon/100
            total_price_sin_cupon = total_price
            total_price_cupon = parseFloat((total_price * descuento_cupon).toFixed())
            total_price_descuento_cupon = parseFloat((total_price_descuento * descuento_cupon).toFixed())
            if(total_price_descuento < 0 ||total_price_descuento_cupon < 0){
              total_price_descuento = 0.01
              total_price_descuento_cupon = 0.01
              total_price_cupon = 0.01
            }
            pedido.total_price_original= total_price_sin_cupon
            pedido.total_price= total_price_cupon
            pedido.total_price_descuento =total_price_descuento_cupon
            let totales={total:total,total_price_original:total_price_sin_cupon, total_price:total_price_cupon, total_price_descuento:total_price_descuento_cupon};
            console.log(pedido, totales);
            localStorage.setItem('pedido',JSON.stringify(pedido));
            resolve(totales);
          }
        })
      })
    // })
  }
}
