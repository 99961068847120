import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataResolverService } from './resolver/data-resolver.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login/:i?',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'hace-pedido',
    loadChildren: () => import('./pages/hace-pedido/hace-pedido.module').then( m => m.HacePedidoPageModule)
  },
  {
    path: 'cuponera',
    loadChildren: () => import('./pages/cuponera/cuponera.module').then( m => m.CuponeraPageModule)
  },
  {
    path: 'promociones',
    loadChildren: () => import('./pages/promociones/promociones.module').then( m => m.PromocionesPageModule)
  },
  {
    path: 'producto/:id',
    resolve:{
      special: DataResolverService
    },
    loadChildren: () => import('./pages/producto/producto.module').then( m => m.ProductoPageModule)
  },
  {
    path: 'carrito',
    loadChildren: () => import('./pages/carrito/carrito.module').then( m => m.CarritoPageModule)
  },
  {
    path: 'locales-delivery',
    loadChildren: () => import('./pages/locales-delivery/locales-delivery.module').then( m => m.LocalesDeliveryPageModule)
  },
  {
    path: 'form-pago',
    loadChildren: () => import('./pages/form-pago/form-pago.module').then( m => m.FormPagoPageModule)
  },
  {
    path: 'compra-ok',
    loadChildren: () => import('./pages/compra-ok/compra-ok.module').then( m => m.CompraOkPageModule)
  },
  {
    path: 'metodo-envio',
    loadChildren: () => import('./pages/metodo-envio/metodo-envio.module').then( m => m.MetodoEnvioPageModule)
  },
  {
    path: 'metodo-pago',
    loadChildren: () => import('./pages/metodo-pago/metodo-pago.module').then( m => m.MetodoPagoPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'envase',
    loadChildren: () => import('./pages/envase/envase.module').then( m => m.EnvasePageModule)
  },
  {
    path: 'sabores/:i',
    loadChildren: () => import('./pages/sabores/sabores.module').then( m => m.SaboresPageModule)
  },
  {
    path: 'direccion-delivery',
    loadChildren: () => import('./pages/direccion-delivery/direccion-delivery.module').then( m => m.DireccionDeliveryPageModule)
  },
  {
    path: 'lista-locales',
    loadChildren: () => import('./pages/lista-locales/lista-locales.module').then( m => m.ListaLocalesPageModule)
  },
  {
    path: 'editar-perfil',
    loadChildren: () => import('./pages/editar-perfil/editar-perfil.module').then( m => m.EditarPerfilPageModule)
  },
  {
    path: 'ordenes',
    loadChildren: () => import('./pages/ordenes/ordenes.module').then( m => m.OrdenesPageModule)
  },
  {
    path: 'recuperar-contrasena',
    loadChildren: () => import('./pages/recuperar-contrasena/recuperar-contrasena.module').then( m => m.RecuperarContrasenaPageModule)
  },
  {
    path: 'horario-retiro',
    loadChildren: () => import('./pages/horario-retiro/horario-retiro.module').then( m => m.HorarioRetiroPageModule)
  },  {
    path: 'notificaciones',
    loadChildren: () => import('./pages/notificaciones/notificaciones.module').then( m => m.NotificacionesPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
