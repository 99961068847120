import { Component } from '@angular/core';

import { Platform,AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './services/user.service';
import { AppVersion } from '@ionic-native/app-version/ngx';

import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { CremolattiService } from './services/cremolatti.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  user: any;
  ver: any;
  public token: string;
  private hasPermission;


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private userService:UserService,
    private appVersion: AppVersion,
    private fcm: FCM,
    private alertCtrl: AlertController,
    private cremolattiService:CremolattiService

  ) {
    this.initializeApp();
  }

  initializeApp() {
    
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.userData();
      this.getVersion();
     
      
    });
  }

  async userData(){
    let a = localStorage.getItem('usuario')
    if(a != "undefined"){

      let usuario = JSON.parse(a)
      console.log(usuario)
      this.user = usuario
    }
    // await this.userService.get_usuario()

    console.log(this.user)
  }

  getVersion(){
    this.appVersion.getVersionNumber().then((version)=>{
      console.log(version)
      localStorage.setItem("versionapp", version);
      console.log(version);
      this.ver = version;
      console.log(this.ver)
      let cadena = version,
      separador = ".",
      arreglo = cadena.split(separador);

      this.cremolattiService.configuraciones().then((data:any)=>{
       console.log(data)
        if(data.estado == 'OK'){
         const version_app = data.configuraciones.find(i => i.variable == 'version_app')
         console.log(version_app)
          if(version_app.status == 'active'){
              version_app.valor
            console.log(this.ver)
            const arregloBd = version_app.valor.split(".");
            console.log(arreglo, arregloBd)
            if (arreglo[0] < arregloBd[0]) {
              alert("Actualice la App");
            }else if ( arreglo[1] < arregloBd[1]) {
              alert("Hay una nueva versión, se recomienda actualizar");
            } else {
              console.log("ok");
            }
            console.log(version_app.valor)
            return version_app.valor
          } 
        }
      })
    })
  }

  //NOTIFICACIONES INICIO
  
  subscribeToTopic() {
    this.fcm.subscribeToTopic('general');
    console.log('canal general')
    }
    
  private async initFcm(){
    await this.platform.ready();
  
    this.hasPermission = await this.fcm.requestPushPermission();
    console.log('CHECK hasPermission:', this.hasPermission);

    this.token = await this.fcm.getToken();
    console.log('getToken result: ', this.token);
    localStorage.setItem('tokenFcm', this.token);

    
    this.fcm.onTokenRefresh().subscribe(newToken => {
    console.log(newToken);
    this.token = newToken;
    console.log('onTokenRefresh received event with: ', newToken);
    // Register your new token in your back-end if you want
    // backend.registerToken(token);
  localStorage.setItem('tokenFcm', this.token);

  });
  
  
    console.log('Subscribing to new notifications');
   await this.fcm.onNotification().subscribe(async data => {
  if (data.wasTapped) {
    console.log("Received in background", data);
  } else {
    console.log("Received in foreground",data);
    let confirm = await this.alertCtrl.create({
      header: data.title,
      message: data.body,
      buttons: [{
          text: 'Aceptar',
          handler: () => {
          }
        }],
    });
   await confirm.present();
  }
  //  alert( JSON.stringify(data) );
  });
  this.subscribeToTopic();

  }

  
    unsubscribeFromTopic() {
      this.fcm.unsubscribeFromTopic('general');
    } 
}
