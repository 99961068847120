import { Injectable } from '@angular/core';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { AlertController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userdata: any;
  ver:any;
  tok:any;
  usuario: any;
 url:string = 'https://elsol.franchup.com/api/'
  constructor(
              private googlePlus:GooglePlus, 
              private alertController:AlertController,
              private http:HttpClient) { 
               
              }

              googleLogin(){

                return new Promise( (resolve, rejects)=>{
                  this.googlePlus.login({})
                        .then((result)=>{
                          console.log(result)
                          //  this.userdata = result
                          //  this.save_usuario()
                          //  return userdata
                          //  .then((userdata)=>{
                             // this.presentAlert(result.displayName, result.email,'Token de Acceso: '+ result.accessToken)
                             let user = {
                               display_name:result.displayName,
                               email:result.email,
                               id_usuario:result.userId,
                               familyName:result.familyName,
                               givenName:result.givenName
                              
                              }
                               console.log(user)
                              this.loginCremolattiGoogle(user).then((data:any)=>{
                                console.log(user)
                                if(data){
                                  resolve(true)
                                }else{
                                  resolve(true)
                                }
                                // this.userdata = data.usuario
                            //     this.save_usuario()
                            // }).catch((data)=>{
                            //   console.log(data)
                            })
                        // })
                        // .catch((err)=>{
                        //   console.log(err)
                        //   this.presentAlert('No hemos podido logear tu cuenta')
                        //   rejects(true)
                        // })
                        // resolve(true)
                      })   
                      .catch(
                        (error) => {
                          console.log(error) 
                          console.log(this.userdata)
                          this.presentAlert('No hemos podido logear tu cuenta')
                          // this.userdata = `Error ${JSON.stringify(error)}`}
                      })
                })  
              }
            
 loginCremolattiGoogle(user){
  this.ver = localStorage.getItem('versionapp');
  this.tok = localStorage.getItem('tokenFcm');
  console.log(this.ver, this.tok);
                  user.data_device={"version":this.ver, "token":this.tok};
                  console.log(user); 
                  return new Promise ((resolve)=>{
                  this.http.post(`${this.url}login_google`, user).subscribe((data:any)=>{
                    console.log(user, data)
                    if(data.estado == "OK"){
                      this.userdata = data.usuario           
                      localStorage.setItem('usuario',JSON.stringify(this.userdata))
                          resolve(true)
                        }else{
                          resolve(true)
                        }
                  }, (error)=>{console.log(error)})
                  
                })
              }

  googleLogOut(){
    return new Promise((resolve, rejects)=>{
      this.googlePlus.logout().then((data)=>{console.log(data)
                                // this.presentAlert('Has Deslogeado tu Usuario.')
                                resolve(true)
                              }).catch((err)=>{
                                console.log(err)
                                // this.presentAlert('Hubo un error al Deslogearte de Google')
                                rejects(true)
                              })

    })

  }

  googleDisconnect(){
    return new Promise((resolve, rejects)=>{

      this.googlePlus.disconnect().then((data)=>{
        console.log(data)
        this.presentAlert('Has desconectado tu Cuenta de Google')
        resolve(true)
      }).catch((err)=>{
        console.log(err)
        this.presentAlert(err)
        rejects(true)
      })
    })
  }

  googleTrySignIn(){
  return new Promise((resolve, rejects)=>{
    this.googlePlus.trySilentLogin({
      offline: false
    })
      .then((data)=>{
        // console.log(data)
        let user = {
          display_name:data.displayName,
          email:data.email,
          id_usuario:data.userId,
          familyName:data.familyName,
          givenName:data.givenName
        }
        console.log(user)
        this.loginCremolattiGoogle(user).then((data:any)=>{
          // console.log(data)
          if(data){
            //  this.presentAlert('Estas logeado con tu Cuenta de Google')
            //  this.userdata = data 
             console.log(this.userdata)
             resolve(true)
          }        
        })
      })
      .catch((err)=>{
        console.log(err)
        //  this.presentAlert('Bienvienido a Cremolatti App!!<br> Puedes visitar la App pero no estas Logeado')
        rejects(false)
      })
    })  
  }

  registroCremolatti(user){

    return new Promise ((resolve)=>{
      let data = {
        'name': user.name,
        'email':user.email,
        'phone':"23456789",
        'password':user.password,
        'password_confirmation':user.password_confirmation
      }

      this.http.post(`${this.url}register`,data ).subscribe((data)=>{
        console.log(data)
       
        resolve(true)
      }, (error)=>{
        resolve(false)
        console.log(error)
        this.presentAlert(('Hubo un Error y no pudimos Registrarte. Por favor Ingresa tus datos nuevamente'))
      })
    })
  }
  
  loginCremolatti(user){
    return new Promise((resolve, rejects)=>{

      this.http.post(`${this.url}login`, user).subscribe((data:any)=>{
        console.log(data)
        if(data.estado == 'OK'){
          this.userdata = data.usuario
          this.save_usuario().then((data)=>{
            if(data){
              resolve(true)
            }
          })
        }else{
          this.presentAlert(data.mensaje)
          resolve(false)
        }
      }, (error)=>{
        console.log(error)
        resolve(false)
      })
    })
  }

  
  logout(){
    return new Promise((resolve, rejects)=>{
      // let data = JSON.stringify(orden)
    // let id =  user.id
      this.http.get(`${this.url}logout`).subscribe((data)=>{
        console.log(data)
        resolve(data) 
      }, (error)=>{
        console.log(error)
      })
    })
  }

  editUser(user, id){
    return new Promise((resolve, rejects)=>{
      // let data = JSON.stringify(orden)
    
      this.http.post(`${this.url}update_datos/${id}`, user).subscribe((data)=>{
        console.log(data)
        resolve(data) 
      }, (error)=>{
        console.log(error)
      })
    })
  }

  async save_usuario() {
    await localStorage.setItem('usuario',JSON.stringify(this.userdata));
    return true
   }

   async get_usuario() {
    let user = await localStorage.getItem('usuario')
    if(user != "undefined"){
      let usuario =  JSON.parse(user);
      return usuario
    }else{
      return false
    }
  }

  async presentAlert( msg, header?, subheader?, ) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: subheader,
      subHeader: header,
      message: msg,
      buttons: ['OK']
    });
  
    await alert.present();
  }
}
