import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AppComponent } from '../../app.component';
import { NavParams, MenuController } from '@ionic/angular';
import { CarritoService } from '../../services/carrito.service';


@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() usuario;
  // usuario
  @Input() version;

  constructor(  private menu: MenuController, private router:Router, private userService:UserService, 
              private app:AppComponent, private navParams:NavParams, private carritoService:CarritoService) {
                
               }

  ngOnInit() {
  }
  ionViewWillEnter(){
    this.getUsuario() 
    
  }
    
  user(){
    console.log('click')
     this.getUsuario()
     if(this.usuario){
       this.router.navigate(['perfil']);       
     }else{
       this.router.navigate(['login'])
     }
  }
  
  async getUsuario(){
    await this.userService.get_usuario().then((data)=>{

      this.usuario = data
      console.log(this.userService.usuario)
    }) 
   }

  logout(){ 
    localStorage.removeItem('usuario')
    
    this.userService.googleLogOut().then(()=>{
      this.userService.logout().then((data)=>{
        this.menu.close();
        console.log(data)
        this.app.initializeApp()  
        localStorage.removeItem('pedido')
        this.carritoService.init_order().then(()=>{

          this.router.navigate(['home'])
        })
      })
    })
    
  }
  
  login(){
    let i =0
    // this.navCtrl.navigateRoot('login', {i:i})
     this.router.navigate(['login',i]);
     this.navParams.data ={i:i}
  }

  cuponera(){
    this.router.navigate(['cuponera'])
    console.log('cuponera')
  }
  promociones(){
    this.router.navigate(['promociones'])
    console.log('promociones')
  }

  home(){
    this.router.navigate(['home'])
    console.log('home')
  }

  ordenes(){
    this.router.navigate(['ordenes'])
  }

  nuestraFuncion(){
    this.getUsuario() 

  }
}
