// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDERGeovNJH0xp6lZsyXcxgoukn07VZroo",
    authDomain: "general-add38.firebaseapp.com",
    projectId: "general-add38",
    storageBucket: "general-add38.appspot.com",
    messagingSenderId: "833793590720",
    appId: "1:833793590720:web:2c870eb582c9419699b425",
    measurementId: "G-M8R4TQ025R"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
