import { UserService } from './../../services/user.service';
import { CarritoService } from './../../services/carrito.service';
import { Component, OnInit , OnChanges} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit ,OnChanges {
  total: number;
  usuario: any;

  constructor(public carritoService:CarritoService, private router:Router, private userService:UserService) { }

  ngOnInit() {
    // this.carritoService.total_cart().then( (data:number)=>{
    //   this.total = data;
    //   console.log(data)
    // });
  }
  ngOnChanges(){
    // this.carritoService.total_cart().then( (data:number)=>{
    //   this.total = data;
    //   console.log(data)
    // });
  }
  
  ionViewWillEnter(){ 
    
  }

  carrito(){
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    console.log(usuario,"header")
      if(usuario) {
        this.router.navigate(['carrito']);
        // console.log(usuario)
      } else {
        this.router.navigate(['login']);
        // console.log(usuario)
      }    
  
  }

  async cargarUsuario() {
    // this.usuario = await this.userService.get_usuario();
    
    //  this.userService.usuario;
  }
}
