import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'counter',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
})
export class InputNumberComponent  {
  counterValue = 0;
  private _min: number;
  private _max: number;
  private _step: number;

  @Output() counterChange = new EventEmitter();

  @Input()
  get counter() {
    return this.counterValue;
  }

  set counter(val) {
    this.counterValue = val;
    this.counterChange.emit(this.counterValue);
  }
  
  @Input()
  set min(m: number) {
    this._min = m;
  }
  @Input()
  set max(m: number) {
    this._max = m;
  }
  @Input()
  set step(m: number) {
    this._step = m;
  }

  @Input()
  set start(s: number) {
    this.counterValue = s;
  }

  decrement() {
    this.counter = (this.counter - this._step) >= this._min ? this.counter - this._step : this.counter;
  }

  increment() {
    this.counter = (Number(this.counter) + Number(this._step)) <= Number(this._max) ? Number(this.counter) + Number(this._step) : this.counter;
  }


}
