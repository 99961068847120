
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { NgxMercadopagoModule } from 'ngx-mercadopago';
import { environment } from '../environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAnalyticsModule, ScreenTrackingService  } from '@angular/fire/analytics';

import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, 
    IonicModule.forRoot({mode:'md', backButtonText:''}), 
    AppRoutingModule, 
    HttpClientModule, 
    ComponentsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireAnalyticsModule,
    NgxMercadopagoModule.forRoot({
      publishKey: 'APP_USR-db5591ef-b78b-41a2-be4c-4151ecf3ad5e',
      pathSDK: 'https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js'
  })
  ],    
  providers: [
    StatusBar,
    SplashScreen,
    GooglePlus,  
    NavParams,
    ScreenTrackingService,
    Geolocation,
    AppVersion,
    FCM,
    NativeGeocoder,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
