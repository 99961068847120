import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class CremolattiService {
  url='https://elsol.franchup.com/api/';

  constructor(private userService:UserService, private http:HttpClient, private alertController:AlertController) { }


  todosproductos(){
    return new Promise((resolve, rejects)=>{

      this.http.get(`${this.url}listadoproductos`).subscribe((data)=>{
        console.log(data)
        resolve(data)
      }, error=>{
        rejects(error)
        console.log(error)
      })
    }).catch((error)=>{
      this.presentAlert(error)
    })
  }

  cupon(cupon){
    return new Promise((resolve, rejects)=>{
      // let data = JSON.stringify(orden)
      // let id=4
      this.userService.get_usuario().then((data)=>{
           
          let user = data
          console.log(user)
          this.http.get(`${this.url}cupon/${cupon}/${user.cliente}`).subscribe((data)=>{
            console.log(data)
            resolve(data) 
          }, (error)=>{
            console.log(error)
          })
        })
     
    })
  }

  productos(id){
    return new Promise((resolve, rejects)=>{

      this.http.get(`${this.url}productos/${id}`).subscribe((data)=>{
        console.log(data)
        resolve(data)
      }, error=>{
        rejects(error)
        console.log(error)
      })
    }).catch((error)=>{
      this.presentAlert(error)
    })
  }

  categor(){
    return new Promise((resolve, rejects)=>{

      this.http.get(`${this.url}tiposproductos`).subscribe((data)=>{
        console.log(data)
        resolve(data)
      }, error=>{
        rejects(error)
        console.log(error)
      })
    }).catch((error)=>{
      this.presentAlert(error)
    })
  }


  locales(){
    return new Promise((resolve, rejects)=>{

      this.http.get(`${this.url}locales`).subscribe((data)=>{
        console.log(data)
        resolve(data)
      }, error=>{
        rejects(error)
        console.log(error)
      })
    }).catch((error)=>{
      this.presentAlert(error)
    })
  }

  orden(orden){
    console.log(orden);
    return new Promise((resolve, rejects)=>{
      let data = JSON.stringify(orden)
      this.http.post(`${this.url}pedido`, orden).subscribe((data)=>{
        console.log(data)
        resolve(data)
      }, (error)=>{
        console.log(error)
        rejects(error);
      })
    })
  }

  ordenes(user){
    return new Promise((resolve, rejects)=>{
      // let data = JSON.stringify(orden)
      let id=4
      if(user.id){
         id =  user.id
      }
      this.http.get(`${this.url}listar_pedidos/${id}`).subscribe((data)=>{
        console.log(data)
        resolve(data) 
      }, (error)=>{
        console.log(error)
      })
    })
  }

  localDelivery(coords){
    return new Promise((resolve, rejects)=>{
      // let data = JSON.stringify(orden)
      
      this.http.get(`${this.url}getLocalesByLatLong/${coords.lat}/${coords.lng}`).subscribe((data)=>{
        console.log(data)
        resolve(data) 
      }, (error)=>{
        console.log(error)
        resolve(false)
      })
    })
  }

  configuraciones(){
    return new Promise((resolve, reject)=>{
      this.http.get(`${this.url}configuraciones`).subscribe((data)=>{
        console.log(data)
        resolve(data)
      }, (error)=>{
        console.log(error)
        resolve(false)
      })


    })
  }

  async presentAlert( msg, ) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      message: msg,
      buttons: ['OK']
    });
  
    await alert.present();
  }
}
